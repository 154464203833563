import { flattenContent, staticSuperTable } from '~utils/mapping-filters'

const mapEntry = (entry) => {
  const masthead = staticSuperTable(entry.masthead)
  const metadata = staticSuperTable(entry.metadata)
  const contactBusinessEnquiries = staticSuperTable(entry.contactBusinessEnquiries)

  return {
    masthead: {
      ...flattenContent(masthead, 'subheading'),
      image: masthead.image[0]
    },
    contactBusinessEnquiries: {
      body: contactBusinessEnquiries.body ? contactBusinessEnquiries.body.content : '',
    },
    metadata
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    entry: mapEntry(craft.entry)
  }
}
