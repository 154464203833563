import React from 'react'

import PageLayer from '~components/PageLayer'
import SEO from '~components/SEO'
import { MastheadBusinessEnquiries } from '~components/Masthead'
import { Cell, Box } from '~styles/common'
import Block from '~components/Block'

import { useCraft } from './query'

const BusinessEnquiriesTemplate = () => {
  const { entry } = useCraft()

  return (
    <PageLayer miniLayer={true}>
      <SEO
        title="Business enquiries"
        metadata={entry.metadata}
      />
      <MastheadBusinessEnquiries
        {...entry.masthead}
      />
      <Block>
        <Cell>
          <Box
            as="h2"
            dangerouslySetInnerHTML={{ __html: entry.contactBusinessEnquiries.body }}
          />
        </Cell>
        <Cell />
      </Block>
    </PageLayer>
  )
}

export default BusinessEnquiriesTemplate
