import { useStaticQuery, graphql } from 'gatsby'
import { mapCraft } from './mappings'

const query = graphql`
{
  craft {
    entry(section: businessEnquiries) {
      ...on Craft_BusinessEnquiries {
        title
        masthead {
          heading
          image {
            title
            url
          }
          backgroundColor
        }
        contactBusinessEnquiries {
          body {
            content
          }
        }
        metadata {
          ...metadataFields
        }
      }
    }
  }
}
`

export const useCraft = () => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}
